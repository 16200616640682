import React, { Component } from 'react'
import './Product.css'
import { Header, Container } from 'semantic-ui-react'
import bgGround from '../../images/micro-controller-bg.png'
import { ProductsTab } from './ProductsTab'

export const Product = () => {
  return (
    <div className='product'>
      <div
        className='product__top'
        style={{
          backgroundImage: `url(${bgGround}), linear-gradient(355deg, transparent, #b0cccc), linear-gradient(94deg, #c9c9c9, #fff)`,
        }}
      >
        <Header as={'h1'} size='huge'>
          OptimumP Products
        </Header>
      </div>
      <div className='product__bottom'>
        <ProductsTab/>
      </div>
      <div id='product-description' className='product-description'></div>
      <div id='battery-swapping' className='battery-swapping'></div>
      <div id='beta-schematic' className='beta-schematic'></div>
    </div>
  )
}