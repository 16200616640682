import React, { useEffect, useState } from 'react'
import { Container, Header, Form, TextArea, Button, Loader, Dimmer, Message } from 'semantic-ui-react'
import Lottie from "lottie-react";
import applyLottie from "../../images/lotties/apply-lottie.json";
import './Apply.css'
import axios from 'axios';

const options = [
  { key: '0', text: 'Qualification', value: 'Qualification' },
  { key: '1', text: 'DropOut/Failed', value: 'DropOut/Failed' },
  { key: '2', text: 'Undergraduate', value: 'Undergraduate' },
  { key: '3', text: 'Graduate', value: 'Graduate' },
  { key: '4', text: 'Intermediat', value: 'Intermediat' },
]
const options2 = [
  { key: '0', text: 'Engineering Department', value: 'Engineering Department' },
  { key: '1', text: 'ECE', value: 'ECE' },
  { key: '2', text: 'EEE', value: 'EEE' },
  { key: '3', text: 'Mechanical', value: 'Mechanical' },
  { key: '4', text: 'CSE', value: 'CSE' },
]

export const Apply = () => {
  const [showErrMsg, setShowErrMsg] = useState<string | null>(null);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);
  const [form, setForm] = useState<{
    fullName: string,
    collegeName: string,
    contactNo: string,
    emailId: string,
    qualification: string,
    engineeringDepartment: string,
    expectation: string
  }>({
    fullName: "",
    collegeName: "",
    contactNo: "",
    emailId: "",
    qualification: "",
    engineeringDepartment: "",
    expectation: ""
  })

  useEffect(() => {
    if (showSuccessMsg) {
      setTimeout(() => {
        setShowSuccessMsg(false);
      },2000)
    }
  }, [showSuccessMsg])
  const handleChange = (e: any, {value, name}: any) => {
    setForm({
      ...form,
      [name]: value
    })
  }

  const submitForm = async () => {
    let url = process.env.REACT_APP_FORM_URL;
    if (!url) {
      return;
    }

    url = url.replace("{{FullName}}", form.fullName);
    url = url.replace("{{CollegeName}}", form.collegeName);
    url = url.replace("{{ContactNo}}", form.contactNo);
    url = url.replace("{{EmailId}}", form.emailId);
    url = url.replace("{{Qualification}}", form.qualification);
    url = url.replace("{{EngineeringDepartment}}", form.engineeringDepartment);
    url = url.replace("{{ExpectationFromInternship}}", form.expectation);
    setShowLoader(true);
    const iframe = document.createElement('iframe')
    iframe.src = url;
    iframe.style.display = "none";
    document.body.appendChild(iframe);
    setTimeout(() => {
      document.body.removeChild(iframe);
      setShowLoader(false);
      setForm({
        fullName: "",
        collegeName: "",
        contactNo: "",
        emailId: "",
        qualification: "",
        engineeringDepartment: "",
        expectation: ""    
      })
      setShowSuccessMsg(true);
    }, 1000)
  }

  const validator = (name: string, value: string) => {
    switch(name) {
      case "fullName":
        return value.length >= 3;
      case "collegeName":
        return value.length >= 3;
      case "contactNo":
        return String(value)
          .toLowerCase()
          .match(
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
          );
      case "emailId":
        return String(value)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
        }
  }

  const handleSubmit = async () => {
    if (form.fullName && form.collegeName && form.contactNo && form.emailId) {
      if (!validator("fullName", form.fullName)) {
        setShowErrMsg("Full Name should be atleast 3 characters");
        return;
      }

      if (!validator("collegeName", form.collegeName)) {
        setShowErrMsg("College Name should be atleast 3 characters");
        return;
      }

      if (!validator("contactNo", form.contactNo)) {
        setShowErrMsg("Invalid Contact No");
        return;
      }

      if (!validator("emailId", form.emailId)) {
        setShowErrMsg("Invalid Email Id");
        return;
      }

      setShowErrMsg(null);
      await submitForm();
    } else {
      setShowErrMsg("* fields are required")
    }
  }

  return (
    <div className='apply'>
      <div className='apply__left'>
        <Header as={'h1'} size='huge'>
          Apply For Internship
        </Header>
        <p>
          Gain enough knowledge to help scale through the tech industry
        </p>
        <Lottie animationData={applyLottie} loop={true} />
      </div>
      <div className='apply__right'>
        <Form onSubmit={handleSubmit}>
          <Form.Input
            fluid
            label='Full Name*'
            placeholder='Full Name'
            name="fullName"
            onChange={handleChange}
            value={form.fullName}
          />
          <Form.Input
            fluid
            label='College Name*'
            placeholder='College Name'
            name="collegeName"
            onChange={handleChange}
            value={form.collegeName}
          />
          <Form.Input
            fluid
            label='Contact No*'
            placeholder='Contact No'
            name="contactNo"
            onChange={handleChange}
            value={form.contactNo}
          />
          <Form.Input
            fluid label='Email Id*'
            placeholder='Email Id'
            name="emailId"
            onChange={handleChange}
            value={form.emailId}
          />
          <Form.Select
            fluid
            label='Qualification'
            options={options}
            placeholder='Qualification'
            name="qualification"
            onChange={handleChange}
            value={form.qualification}
          />
          <Form.Select
            fluid
            label='Engineering Department'
            options={options2}
            placeholder='Engineering Department'
            name="engineeringDepartment"
            onChange={handleChange}
            value={form.engineeringDepartment}
          />
          <Form.Field
            control={TextArea}
            label='Expectation from Inernship'
            placeholder='...'
            name="expectation"
            onChange={handleChange}
            value={form.expectation}
          />
          {showErrMsg && <div style={{color: "red"}}>{showErrMsg}</div>}
          <Dimmer active={showLoader}>
            <Loader />
          </Dimmer>
          <div className='apply__btn'>
            <Form.Field primary control={Button}>
              Submit
            </Form.Field>
          </div>
        </Form>
        {showSuccessMsg && <Message positive>
          <Message.Header>Application successfully submitted!!</Message.Header>
        </Message>}
      </div>
    </div>
  )
}