import React, { useState } from 'react'
import { Header, Menu, Icon, MenuItemProps } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { TabNames } from '../Header/Header'
import img from '../../images/footer-image.png'
import './Footer.css'

export const Footer = (props: {
    activeItem: TabNames,
    handleItemClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => void
}) => {
    return (
      <footer className='footer'>
        <div className='footer__top'>
            <div className='footer__item'>
                <img src={img} alt='optimump' height={50} />
                <div className='footer__pvt'>OptimumP Pvt.Ltd.</div>
                <div className='footer__pvt'>Email - info@optimump.com</div>
                <div>
                H. No : 3-4-32, Vijayasree colony, Autonagar, Hyderabad-
                500070India
                </div>
            </div>
            <div className='footer__item'>
                <div className='footer-menu-title'><h4>Quick Links</h4></div>
                <Menu text vertical className='footer-menu'>
                <Menu.Item
                    className='footer-menu-item'
                    name={TabNames.HOME}
                    active={props.activeItem === TabNames.HOME}
                    onClick={props.handleItemClick}
                    as={Link}
                    to='/'
                />
                <Menu.Item
                    className='footer-menu-item'
                    name={TabNames.APPLY}
                    active={props.activeItem === TabNames.APPLY}
                    onClick={props.handleItemClick}
                    as={Link}
                    to='/apply'
                />
                <Menu.Item
                    className='footer-menu-item'
                    name={TabNames.ABOUT_US}
                    active={props.activeItem === TabNames.ABOUT_US}
                    onClick={props.handleItemClick}
                    as={Link}
                    to='/about-us'
                />
                </Menu>
            </div>
            <div className='footer__item'>
                <div className='footer-menu-title'><h4>Products</h4></div>
                <Menu text vertical className='footer-menu'>
                <Menu.Item
                    className='footer-menu-item'
                    name='BMS'
                    active={props.activeItem === TabNames.PRODUCTS}
                    onClick={props.handleItemClick}
                    as={Link}
                    to='/products'
                />
                </Menu>
            </div>
            <div className='footer__item'>
                <Header as={'h4'} size='small' className='item4'>
                Connect with US
                </Header>
                <div className='footer-flex'>
                <div>
                    <Icon
                    name='facebook'
                    size='large'
                    style={{ color: '#4267b2' }}
                    />
                    Facebook
                </div>
                <div>
                    <Icon
                    name='linkedin'
                    size='large'
                    style={{ color: '#0072b1' }}
                    />
                    LinkedIn
                </div>
                </div>
            </div>
        </div>
      </footer>
    )
}
