import React from 'react'
import './Home.css'
import { Container, Header, Button } from 'semantic-ui-react'
import bgGround from '../../images/hero-bg.png'
import bgImage from "../../images/second-section-bg2.png";
import img1 from "../../images/feature-1.png";
import img2 from "../../images/feature-2.png";
import img3 from "../../images/feature-3.png";
import img4 from "../../images/feature-4.png";
import img5 from "../../images/feature-5.png";
import img6 from "../../images/feature-6.png";
import img7 from "../../images/feature-7.png";

export const MottoSection = () => {
  return (
    <section className='hero'>
      <div className='hero__flex'>
        <div className='hero__left'>
          <Header as='h1' size='huge' className='hero_heading'>
            WE MAKE BATTERIES WORK
          </Header>
          <p className='hero_paragraph'>
            Maximise safety, performance, and longevity for your lithium
            battery with LiBAL Battery Management Systems
          </p>
          <div className='hero_button'>
            <Button primary><a href='/product' style={{color: "white"}}>Learn More</a></Button>
          </div>
        </div>
        <div className='hero__right' style={{ backgroundImage: `url(${bgGround})` }}>
        </div>
      </div>
    </section>
  )
}

export const DescriptionSection = () => {
  return (
    <section className="second-section">
      <div className="second-section__left">
        <div
          className='image'
          style={{
            backgroundImage: `url(${bgImage}), linear-gradient(130deg, #cedcb4, #77b1ac)`,
          }}
        ></div>
      </div>
      <div className="second-section__right">
        <h1 className='title'>Battery Management System</h1>
        <p className='description1'> At OptimumP, we offer good quality <strong>Battery Management System (BMS)</strong>. The Battery Management System (BMS) we offer are manufactured under strict quality controls and all the BMS go through high standard Quality Contol before reaching to Indian Market.</p>
        <p>
          We also deal in Smart Battery Management System. Our smart BMS
          is specially designed for rechargeable lithium battery packs to
          enhance performance of complete solutions. The communicative BMS
          monitors all cell’s current and voltage fluctuations in pack and
          temperature of the pack.
        </p>
        <div className='description-button'>
          <Button primary><a href='/product' style={{color: "white"}}>Learn More</a></Button>
        </div>
      </div>
    </section>
  ); 
}

export const FeaturesSection = () => {
  return (
    <section className="feature">
      <h1 className="feature__heading">Features</h1>
      <div className='feature__content'>
        <div className="feature__flex">
          <div className="feature__item">
            <div className="feature__item-heading">
              <img
                src={img1}
                alt="Over Voltage Protection"
                className="feature__item-image"
              />
            <h3><strong className="bold-text-2">Over Voltage <br></br>Protection</strong></h3>
            </div>
            <div className="feature__item-desc">
              <p>
                Protects the battery pack from over-charging by terminating
                the charging process, when pack is fully charged
              </p>
            </div>
          </div>
          <div className="feature__item">
            <div className="feature__item-heading">
              <img
                src={img2}
                alt="Under Voltage
                Protection
                "
                className="feature__item-image"
              />
              <h3><strong className="bold-text-2">Under Voltage<br></br>Protection</strong></h3>
            </div>
            <div className="feature__item-desc">
              <p>
                Protects the battery pack from over-discharging by terminating
                the discharging process, when pack is completely drained
              </p>
            </div>
          </div>
          <div className="feature__item">
            <div className="feature__item-heading">
              <img
                src={img3}
                alt="Temperature
                Protection"
                className="feature__item-image"
              />
              <h3><strong className="bold-text-2">Temperature<br></br>Protection</strong></h3>
            </div>
            <div className="feature__item-desc">
              <p>
                Over temperature and under temperature protection while
                charging or discharging
              </p>
            </div>
          </div>
          <div className="feature__item">
            <div className="feature__item-heading">
              <img
                src={img4}
                alt="Voltage
                Protection-delay"
                className="feature__item-image"
              />
              <h3><strong className="bold-text-2">Voltage<br></br>Protection-delay</strong></h3>
            </div>
            <div className="feature__item-desc">
              <p>
                Over voltage protection and Undervoltage protections are as
                fast as 0.5 Seconds
              </p>
            </div>
          </div>
        </div>
        <div className="feature__flex">
          <div className="feature__item">
            <div className="feature__item-heading">
              <img
                src={img5}
                alt="Power
                consumption"
                className="feature__item-image"
              />
              <h3><strong className="bold-text-2">Power<br></br>Consumption</strong></h3>
            </div>
            <div className="feature__item-desc">
              <p>
                During normal operation BMS consumes as low as 4 micro amperes
                and system goes to sleep under no load condition
              </p>
            </div>
          </div>
          <div className="feature__item">
            <div className="feature__item-heading">
              <img
                src={img6}
                alt="Cell
                Balancing"
                className="feature__item-image"
              />
              <h3><strong className="bold-text-2">Cell<br></br>Balancing</strong></h3>
            </div>
            <div className="feature__item-desc">
              <p>
                Passive balancing and Active balancing is done based on
                intelligent algorithm. Balancing currents up to 50mA and more
                is possible
              </p>
            </div>
          </div>
          <div className="feature__item">
            <div className="feature__item-heading">
              <img
                src={img7}
                alt="Over current
                protection"
                className="feature__item-image"
              />
              <h3><strong className="bold-text-2">Over Current<br></br>Potection</strong></h3>
            </div>
            <div className="feature__item-desc">
              <p>
                Over current protection is triggered in just 18milli seconds
                when threshold limit is reached
              </p>
            </div>
          </div>
          <div className="feature__item">
            <div className="feature__item-heading">
              <img
                src={img7}
                alt="Over current
                protection"
                className="feature__item-image"
              />
              <h3><strong className="bold-text-2">Short Circuit<br></br>Potection</strong></h3>
            </div>
            <div className="feature__item-desc">
              <p>
                Resettable short-circuit protection just like MCB without any
                damage to the pack
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export const Home = () => {
  return (
    <>
      <MottoSection />
      <DescriptionSection />
      <FeaturesSection />
    </>
  )
}
