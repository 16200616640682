import { Routes, Route } from "react-router-dom";
import { Header, TabNames } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { Apply } from "./components/Apply/Apply";
import { Home } from "./components/Home/Home";
import { Product } from "./components/Product/Product";
import { Contact } from "./components/Contact/Contact";
import { useEffect, useState } from "react";
import { MenuItemProps } from "semantic-ui-react";

export const App = () => {
  const [activeItem, setActiveItem] = useState<TabNames>(TabNames.HOME);

  useEffect(() => {
    const pathname = window.location.pathname;        
    switch(pathname) {
        case "/product":
            setActiveItem(TabNames.PRODUCTS);
            break;
        case "/apply":
            setActiveItem(TabNames.APPLY);
            break;
        case "/about-us":
            setActiveItem(TabNames.ABOUT_US);
            break;
        default:
            setActiveItem(TabNames.HOME);
            break;
    }        
  }, [])

  const handleItemClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
    if (data.name) {
        setActiveItem(data.name as TabNames);
    }
  }

  return (
    <>
      <Header activeItem={activeItem} handleItemClick={handleItemClick} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="/about-us" element={<Contact />} />
        </Routes>
      <Footer activeItem={activeItem} handleItemClick={handleItemClick}/>
    </>
  );
}