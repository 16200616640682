import React, { useEffect, useState } from 'react'
import { Menu, Container, MenuItemProps } from 'semantic-ui-react'
import './Header.css'
import logo from '../../images/optimump-icon.png'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'

export enum TabNames {
    HOME = 'HOME',
    PRODUCTS = "PRODUCTS",
    APPLY = "APPLY",
    ABOUT_US = "ABOUT US"
}

export const Header = (props: {
    activeItem: TabNames,
    handleItemClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => void
}) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <div className='top-bar'>
            <a className='logo-link' href='/'>
                <img src={logo} alt='optimump' className='logo'/>
            </a>
            <div className='mobile-bar' onClick={() => {
                setOpen(!open)
            }}>
                <Icon name='bars' size='big' />
            </div>
            <Menu fixed='top' className='header-layout' stackable>
                <Menu.Menu
                    position='right'
                    className={open ? 'nav-bar-open' : 'nav-bar-hide'}
                >
                    <Menu.Item
                        name={TabNames.HOME}
                        active={props.activeItem === TabNames.HOME}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                        }}
                        as={Link}
                        to='/'
                    />
                    <Menu.Item
                        name={TabNames.PRODUCTS}
                        active={props.activeItem === TabNames.PRODUCTS}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                        }}
                        as={Link}
                        to='/product'
                    />
                    <Menu.Item
                        name={TabNames.APPLY}
                        active={props.activeItem === TabNames.APPLY}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                        }}
                        as={Link}
                        to='/apply'
                    />
                    <Menu.Item
                        name={TabNames.ABOUT_US}
                        active={props.activeItem === TabNames.ABOUT_US}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                        }}
                        as={Link}
                        to='/about-us'
                    />
                </Menu.Menu>
            </Menu>
        </div>
    )
}