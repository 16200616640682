import React from 'react'
import './Product.css'
import { Header, Tab, Button } from 'semantic-ui-react'

const panes = [
  {
    menuItem: 'Battery Management System',
    render: () => (
      <Tab.Pane>
        <div className='tab'>
          <div className='bms-section'>
            <div className='bms-item'>
              <div className='title'>Alpha-X</div>
              <div className='sub-title'>Alpha-60, Alpha-100</div>
              <div className='specification'>
                <Header as={'h4'} size='small'>
                  Specifications:
                </Header>
                <p>
                  6-Cells to 16 Cells, CAN2.0B,
                  Micro-SD Card,
                  4 Temp, Buzzer
                </p>
              </div>
              <div className='tab__btn__flex'>
                <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button>
              </div>
            </div>
            <div className='bms-item'>
              <div className='title'><span>Gamma</span></div>
              <div className='sub-title'><span>Gamma-400, <br></br><br></br>Gamma-600</span></div>
              <div className='specification'>
                <Header as={'h4'} size='small'>
                  Specifications:
                </Header>
                <p>
                  32-512 Cells,
                  Contractor,
                  Hall Probe Sensor
                </p>
              </div>
              <div className='tab__btn__flex'>
                <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button>
              </div>
            </div>
            <div className='bms-item'>
              <div className='title'>Harari</div>
              <div className='description'>
                <Header as={'h4'} size='small'>
                  Description:
                </Header>
                <p>
                  Harari is an Alpha model BMS
                  parameter programming device, which
                  downloads its settings from BatInfo
                  and stores it in its memory
                  permanently, until reprogrammed.
                </p>
              </div>
              <div className='tab__btn__flex'>
                <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button>
              </div>
            </div>
            <div className='bms-item'>
              <div className='title'>Fujio</div>
              <div className='description'>
                <Header as={'h4'} size='small'>
                  Description:
                </Header>
                <p>
                  Fujio is a wireless blackbox data logger
                  compatible with Alpha and Gamma
                  model Battery Management System.
                </p>
              </div>
              <div className='tab__btn__flex'>
                <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button>
              </div>
            </div>
          </div>
        </div>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Battery Monitoring System',
    render: () => (
      <Tab.Pane>
        <div className='tab'>
          <Header as={'h2'} size='large'>
            Bets-Y (cell performance monitor)
          </Header>
          <Header as={'h3'} size='large'>
           Li-ion, Ni-Cd, Li-Polymer, LTO
          </Header>
          <div className='tab__flex'>
            <div className='tab__flex__left'>
              <Header as={'h4'} size='small'>
                Description:
              </Header>
              <p>
                <span>Beta is a highly integrated , high-accuracy wireless battery monitoring system for 3 series to 512 series battery pack. This system comes in Master-Slave configuration,where every slave can be connected up-to 16 cells . A dedecated slave for hall probe current sensor is used for high accurate current measurments.</span>
              </p>
            </div>
          </div>
          <div className='tab__btn__flex'>
            <Button primary><a href='#beta-schematic' style={{color: "white"}}>Learn More</a></Button>
          </div>
        </div>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Telemteric System',
    render: () => (
      <Tab.Pane>
        <div className='tab'>
          <Header as={'h2'} size='large'>
            Telemetric System
          </Header>
          <Header as={'h3'} size='large'>
            In testing phase, will be available soon.
          </Header>
          <div className='tab__btn__flex'>
            <Button primary>Learn More</Button>
          </div>
        </div>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Battery Swapping Solution',
    render: () => (
      <Tab.Pane>
        <div className='tab'>
          <Header as={'h2'} size='large'>
          Battery Swapping Solution
          </Header>
          <Header as={'h3'} size='large'>
            In testing phase, will be available soon.
          </Header>
          <div className='tab__btn__flex'>
            <Button primary><a href='#battery-swapping' style={{color: "white"}}>Learn More</a></Button>
          </div>
        </div>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Thermal Management System',
    render: () => (
      <Tab.Pane>
        <div className='tab'>
          <Header as={'h2'} size='large'>
            Thermal Management System
          </Header>
          <Header as={'h3'} size='large'>
            In testing phase, will be available soon.
          </Header>
          <div className='tab__btn__flex'>
            <Button primary>Learn More</Button>
          </div>
        </div>
      </Tab.Pane>
    ),
  },
]

export const ProductsTab = () => {
  return (
    <Tab menu={{ fluid: true, vertical: true, tabular: true }} panes={panes} />
  )
}